/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
}

/* Modal Box */
.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001; /* Above the overlay */
    max-width: 500px; /* Or any other max-width */
    width: 100%; /* Responsive width */
    margin: 0 20px; /* Spacing from screen edges */
}

/* Modal Content */
.modal input[type="text"],
.modal textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal label {
    display: block;
    margin-bottom: 5px;
}

.modal input[type="radio"] {
    margin-right: 10px;
}

/* Buttons */
.modal button {
    background-color: #007bff; /* Blue */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.modal button:hover {
    background-color: #0056b3; /* Darker blue */
}

.modal button.close-button {
    background-color: #dc3545; /* Red */
}

.modal button.close-button:hover {
    background-color: #c82333; /* Darker red */
}
