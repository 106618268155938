.cart-items {
  background: #f6f9fc;
  height: auto;
  padding: 50px 0;
}

.cart-items .cart-details {
  width: 70%;
}

.cart-items .cart-total {
  width: 30%;
  margin-top: 30px;
  margin-left: 30px;
  height: 130px;
}
.cart-list {
  background: white;
  margin-top: 30px;
}
.cart-items .img {
  width: 150px;
  height: 150px;
}
.cart-items img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart-items .cart-details h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.cart-items .cart-details h4 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 50px;
  color: grey;
}
.cart-items h4 span {
  color: #e94560;
  margin-left: 20px;
  font-weight: 500;
}
.removeCart {
  background: none;
  font-size: 25px;
  text-align: right;
  margin-right: 10px;
}
.cartControl {
  margin-top: 50px;
}
.cartControl button {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  font-size: 20px;
}
.incCart {
  background: none;
  border: 1px solid rgb(3 0 71 / 9%);
  color: #e94560;
}
.desCart {
  background: #f6f9fc;
}
.cart-total h4 {
  font-size: 15px;
  font-weight: 400;
}
.cart-total h3 {
  font-size: 20px;
  font-weight: 500;
  color: #e94560;
}
.cart-total h2 {
  font-size: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(3 0 71 / 9%);
  padding-bottom: 10px;
  color: #e94560;
}
.no-items {
  color: #e94560;
  font-size: 18px;
  margin-top: 30px;
  height: 130px;
}

.mainscreen
{
	/* min-height: 100vh; */
	width: 100%;
	display: flex;
  flex-direction: column;
    /* background-color: #DFDBE5; */
    /* background-image: url("https://wallpaperaccess.com/full/3063067.png"); */
    color: #e94560;
}

.card {
	/* width: 10%; */
    margin: auto;
    background: white;
    position:center;
    align-self: center;
    top: 50rem;
    border-radius: 1.5rem;
    /* box-shadow: 4px 3px 20px #3535358c; */
    display:flex;
    flex-direction: row;
    
}

.leftside {
	background: #030303;
	width: 25rem;
	display: inline-flex;
    align-items: center;
    justify-content: center;
	border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}



.rightside {
    background-color: #ffffff;
	width: 50%;
	border-bottom-right-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    padding: 1rem 2rem 3rem 3rem;
}

p{
    display:block;
    font-size: 1.1rem;
    font-weight: 400;
    margin: .8rem 0;
}

.inputbox
{
    color:#030303;
	width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: 1.5px solid #ccc;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    font-family: 'Roboto', sans-serif;
    color: #615a5a;
    font-size: 1.1rem;
    font-weight: 500;
  outline:none;
}

.expcvv {
    display:flex;
    justify-content: space-between;
    padding-top: 0.6rem;
}

.expcvv_text{
    padding-right: 1rem;
}
.expcvv_text2{
    padding:0 1rem;
}

.button{
    background: linear-gradient(
135deg
, #753370 0%, #298096 100%);
    padding: 15px;
    border: none;
    border-radius: 50px;
    color: white;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 10px;
    width:100%;
    letter-spacing: .11rem;
    outline:none;
}

.button:hover
{
	transform: scale(1.05) translateY(-3px);
    box-shadow: 3px 3px 6px #38373785;
}

@media only screen and (max-width: 1000px) {
    .card{
        flex-direction: column;
        width: auto;
      
    }

    .leftside{
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
      border-top-right-radius:0;
      border-radius:0;
    }

    .rightside{
        width:50%;
        border-bottom-left-radius: 1.5rem;
        padding:0.5rem 3rem 3rem 2rem;
      border-radius:0;
    }
}
